<template>
  <div>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%">
      <el-table-column prop="id" label="订单号"/>
      <el-table-column prop="login" label="MT4账户"/>
      <el-table-column prop="name" label="客户名称"/>
      <el-table-column prop="symbol" label="交易品种"/>
      <el-table-column prop="cmd" label="交易类型">
        <template slot-scope="scope">
          <span>{{ getCmd(scope.row.cmd) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="volume" label="手数"/>
      <el-table-column prop="openTime" label="开仓时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.openTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="openPrice" label="开仓价格"/>
      <el-table-column prop="closePrice" label="当前价格"/>
      <el-table-column prop="sl" label="止损价格"/>
      <el-table-column prop="tp" label="获利价格"/>
      <el-table-column prop="commission" label="手续费"/>
      <el-table-column prop="storage" label="库存费"/>
      <el-table-column prop="profit" label="当前盈亏"/>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
export default {
  mixins: [initData],
  props:{
    custId: {
      type: Number,
      required: true
    },
    serverId: {
      type: Number,
      required: true
    },
    startTime: {
      type: String,
      default: ''
    },
    endTime: {
      type: String,
      default: ''
    },
    queryType: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      size:5
    }
  },
  watch:{
    startTime( newData,oldData){
        console.log(this.startTime)
        this.init()
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = '/community/crm/getTradeOrderAndTradeUsers'
      const sort = 'od.id,desc'
      this.params = { page: this.page, size: this.size, sort: sort}
      if (this.custId!=null && this.custId!='') { this.params['login'] = this.custId }
      if (this.serverId!=null && this.serverId!='') { this.params['serverId'] = this.serverId }
      if (this.queryType!=null && this.queryType!='') { this.params['queryType'] = this.queryType }
      if (this.startTime!=null && this.startTime!='') { this.params['startTime'] = this.startTime }
      if (this.endTime!=null && this.endTime!='') { this.params['endTime'] = this.endTime }
      return true
    },
    getCmd(cmd){
      switch (cmd) {
        case 0:
          return '买单';
        case 1:
          return '卖单';
        case 2:
          return '买入限价';
        case 3:
          return '卖出限价';
        case 4:
          return '买入止损订';
        case 5:
          return '卖出止损订';
        case 99:
          return '其他';
      }
    }
  }
}
</script>

<style scoped>

</style>
