<template>
  <div class="app-container main">
    <div class="account_data" v-loading="loading" :data="data">
      <div class="item">
        <label class="label">所属平台</label>
        <span>{{ accountInfo.platformName }}</span>
      </div>
      <div class="item">
        <label class="label">用户ID</label>
        <span>{{ accountInfo.userId }}</span>
      </div>
      <div class="item">
        <label class="label">账户</label>
        <span>{{ accountInfo.login }}</span>
      </div>
      <div class="item">
        <label class="label">结算货币</label>
        <span>USD</span>
      </div>
      <!-- <div class="item">
        <label class="label">服务器</label>
        <span>{{ accountInfo.mt4Group }}</span>
      </div> -->
      <div class="item">
        <label class="label">余额</label>
        <span>{{ accountInfo.balance }}</span>
      </div>
      <div class="item">
        <label class="label">浮动盈亏</label>
        <span>{{ accountInfo.profit }}</span>
      </div>
      <div class="item">
        <label class="label">净值</label>
        <span>{{ accountInfo.equity }}</span>
      </div>
      <div class="item">
        <label class="label">保证金</label>
        <span>{{ accountInfo.margin }}</span>
      </div>
      <div class="item">
        <label class="label">可用保证金</label>
        <span>{{ accountInfo.marginFree }}</span>
      </div>
      <div class="item">
        <label class="label">保证金比例</label>
        <span>{{ accountInfo.marginLevel }}</span>
      </div>
      <div class="item">
        <label class="label">创建时间</label>
        <span class="time">{{ accountInfo.regdate }}</span>
      </div>
    </div>
    <div class="info">
      <!-- <div class="account_info">
        <div>
          <span class="title">{{"账户信息"}}</span>
          <el-form ref="form" :model="form" size="small" disabled>
            <el-row>
              <el-col :span="8">
                <el-form-item>
                  <span class="span">{{"MT分组"}}</span>
                  <el-select
                    class="select"
                    v-model="form.mt4Group"
                    placeholder="MT分组"
                    style="width: 300px;"
                  >
                    <el-option
                      v-for="item in mtGroups"
                      :key="item.id"
                      :label="item.groupName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <span class="span">{{"邮箱"}}</span>
                  <el-input class="select" v-model="form.email" placeholder="输入邮箱" style="width: 300px;" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <span class="span">{{"电话号码"}}</span>
                  <el-input class="select" v-model="form.phone" placeholder="输入电话号码" style="width: 300px;" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item>
                  <span class="span">{{"交易状态"}}</span>
                  <el-radio-group class="select" v-model="form.tradeStatus" >
                    <el-radio
                      :label="item.key"
                      :key="item.key"
                      v-for="item in statusOptions"
                    >{{item.display_name}}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <span class="span">{{"登录状态"}}</span>
                  <el-radio-group class="select" v-model="form.loginEnable" >
                    <el-radio
                      :label="item.key"
                      :key="item.key"
                      v-for="item in statusOptions"
                    >{{item.display_name}}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div> -->
      <div class="account_record">
        <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
          <el-tab-pane label="交易记录" name="first">
            <tradeRecord :custId="login" :serverId="serverId"/>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import { parseTime } from "@/utils/index";
import tradeRecord from "@/components/community/customer/accmgr/tradeRecord";
import { mapGetters } from "vuex";
import { initData } from "@/api/data";
import initData1 from "@/mixins/initData";
export default {
  components: {
    tradeRecord,
  },
  mixins: [initData1],
  created() {
    // if (this.mtGroups == null) {
    //   this.$store.dispatch("GetMtGroups");
    // }
    // if (this.categorys == null) {
    //   this.$store.dispatch("GetCategorys");
    // }
    this.login = this.$route.params.login;
    this.serverId = this.$route.params.serverId;
    this.dwCurrTypeOptions = this.wTypeOptions;
    this.init();
  },
  data() {
    return {
      dwLoading: false,
      dwDialog: false,
      accountInfo: {},
      cusOptions: "",
      form: {
        id: this.custId,
        mtGroup: 1,
        leverage: 1,
        tradeStatus: 1,
        loginStatus: 1,
        sendReport: 1,
        modifyPwd: 1,
        email: "",
        phone: ""
      },
      creForm: {},
      levForm: {
        id: this.custId,
        leverage: "",
        sendEmail: true
      },
      leverList: [],
      sup_this: this,
      custId: 0,
      serverId:'',
      data: "",
      activeName: "first",
      statusOptions: [
        { key: 0, display_name: "启用" },
        { key: 1, display_name: "停用" }
      ],
      dwTypeOptions: [
        { key: 1, display_name: "出金" },
        { key: 2, display_name: "入金" }
      ],
      dTypeOptions: [
        { key: 1, display_name: "任务入金" },
        { key: 2, display_name: "返佣入金" },
        { key: 3, display_name: "任务转账入金" },
        { key: 4, display_name: "其他类型入金" }
      ],
      wTypeOptions: [
        { key: 1, display_name: "任务出金" },
        { key: 2, display_name: "任务转账出金" },
        { key: 3, display_name: "其他类型出金" }
      ],
      dwCurrTypeOptions: null
    };
  },
  computed: {
    ...mapGetters(["mtGroups"])
  },
  watch: {
    "form.mt4Group": {
      handler(newVal, oldVal) {
        for (var i = 0; i < this.mtGroups.length; i++) {
          if (newVal == this.mtGroups[i].id) {
            this.setLeverage(this.mtGroups[i].leverageList);
            return;
          }
        }
      },
      deep: true
    },
    data(news, olds) {
      this.accountInfo = news[0];
      this.form.mt4Group = this.accountInfo.mt4Group;
      (this.form.leverage = this.accountInfo.leverage),
        (this.form.tradeStatus = this.accountInfo.enableReadOnly),
        (this.form.loginEnable = this.accountInfo.loginEnable),
        (this.form.email = this.accountInfo.email),
        (this.form.phone = this.accountInfo.phone);
    }
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = "community/crm/srCommunityMtaccount";
      const sort = "cm.id,desc";
      this.params = {
        page: this.page,
        size: this.size,
        sort: sort,
        login: this.login,
        serverId: this.serverId
      };
      return true;
    },
    setLeverage(leverageList) {
      let levList = leverageList.split(",");
      let tempList = [];
      for (let i = 0; i < levList.length; i++) {
        let lev = parseInt(levList[i]);
        let levName = "1:" + levList[i];
        let params = {
          levName: levName,
          lev: lev
        };
        tempList[i] = params;
      }
      this.leverList = tempList;
    },
    showDialog(oper) {
      switch (oper) {
        case 1:
          this.dwDialog = true;
          break;
        case 2:
          this.pwdDialog = true;
          break;
      }
    },
    cancel() {
      this.dwLoading = false;
      this.pwdLoading = false;
      this.dwDialog = false;
      this.pwdDialog = false;
    },
    handleClick(tab, event) {},
  }
};
</script>

<style scoped>
.account_data {
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;
  box-sizing: border-box;
  background-color: rgb(100, 149, 230);
  margin-bottom:10px;
}
.item {
  flex: 1;
  box-sizing: border-box;
  color: #ffffff;
}
.item .label {
  color: #ffffff;
  display: block;
  font-size: 16px;
  max-width: 100%;
  margin-bottom: 5px;
  cursor: default;
}
.item span{
  color: #ffffff;
  display: block;
  font-size: 14px;
  max-width: 100%;
  cursor: default;
}
.account_info{
  background-color:rgba(0,0,0,0.1);
  margin: 20px 0;
  height: 200px;
}
.title {
  display: inline-block;
  margin: 20px 0 20px 20px;
  font-size: 24px;
  font-weight: 700;
}
.span{
  font-size: 14px;
  font-weight: bold;
  margin: 0 20px 0 20px;
}
</style>
