<template> 
<div>
    <div class="item">
        <el-date-picker
            v-model="value2"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
            @change="timeChange()"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right">
        </el-date-picker>
    </div>
     
    <el-collapse v-model="activeNames" @change="handleChange" accordion>
        <el-collapse-item title="持仓记录" name="1" class="item">
             <tradeorderuser :custId="custId" :serverId="serverId" :queryType=1 :startTime="startTime" :endTime="endTime"/>
        </el-collapse-item>
        <el-collapse-item title="交易历史" name="2" class="item">
             <tradeorderuser :custId="custId" :serverId="serverId" :queryType=2 :startTime="startTime" :endTime="endTime"/>
        </el-collapse-item>
    </el-collapse>
</div>
  
</template>

<script>
import tradeorderuser from './tradeorderuser'
export default {
  components:{
    tradeorderuser
  },
  props: {
    custId: {
      type: Number,
      required: true
    },
    serverId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      activeNames: ['1'],
      value2: '',
      startTime:null,
      endTime:null,
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        }       
      }
  },
  methods: {
    timeChange(){
        if(this.value2!=null){
            this.startTime=this.value2[0]
            this.endTime=this.value2[1]
        }
    },
    handleChange(val) {
        // console.log(val);
    }
  }
}
</script>

<style scoped>
.item {
    padding: 10px 10px;
}
</style>
